import './App.css';

import {Suspense, useCallback, useState} from 'react';
import {Theme, ThemeProvider, makeStyles} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import FormDialog from './FormDialog';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import RegistryDialog from './RegistryDialog';
import Typography from '@material-ui/core/Typography';
import theme from './theme';

const appStyles = makeStyles((theme: Theme) => ({
  paperWrapper: {
    height: '80vw',
    width: '80vw',
    [theme.breakpoints.between('sm', 'md')]: {
      height: '60vw',
      width: '60vw',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      height: '40vw',
      width: '40vw',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      height: '30vw',
      width: '30vw',
    },
    padding: '2rem',
  },
  content: {
    height: 'calc(100% + 16px)',
    width: 'calc(100% + 16px)',
  },
  date: {
    fontFamily: 'Roboto',
    fontSize: '5rem',
    height: '5rem',
    textAlign: 'center',
    '& .dot': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '6rem',
      height: '6rem',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '7rem',
      height: '7rem',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '7.5rem',
      height: '7.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '10rem',
      height: '10rem',
    },
  },
  header1: {
    fontSize: '1.2rem',
    textAlign: 'center',
    [theme.breakpoints.up('xl')]: {
      fontSize: '2rem',
    },
  },
  location: {
    fontFamily: 'Roboto',
    fontSize: '1.25rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('xl')]: {
      fontSize: '2.2rem',
    },
  },
  header2: {
    fontSize: '1.2rem',
    textAlign: 'center',
    [theme.breakpoints.up('xl')]: {
      fontSize: '2rem',
    },
  },
  button: {
    textAlign: 'center',
  },
}));

function App() {
  const classes = appStyles();
  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [openRegistry, setOpenRegistry] = useState(false);

  const openRegistryDialog = useCallback(() => {
    setOpenRegistry(true);
  }, [setOpenRegistry]);
  const closeRegistryDialog = useCallback(() => {
    setOpenRegistry(false);
  }, [setOpenRegistry]);

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.paperWrapper}>
        <Grid spacing={2} container className={classes.content}>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.date}>
              {'11'}
              <span className="dot">{'.'}</span>
              {'11'}
              <span className="dot">{'.'}</span>
              {'20'}
            </Typography>
            <Typography className={classes.header1} variant="h4" gutterBottom>
              Arianna & Micah eloped
            </Typography>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Typography className={classes.location} variant="h6">
              Santa Fe
            </Typography>
            <Typography variant="h4" className={classes.header2}>
              Hope to celebrate with you in the future!
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.button}>
            <Button variant="contained" color="primary" onClick={openDialog}>
              Click here for Update
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              onClick={openRegistryDialog}
            >
              Registry
            </Button>
          </Grid>
        </Grid>
        {open ? (
          <Suspense fallback={<LinearProgress variant="indeterminate" />}>
            <FormDialog open={open} onClose={closeDialog} />
          </Suspense>
        ) : null}
        {openRegistry ? (
          <Suspense fallback={<LinearProgress variant="indeterminate" />}>
            <RegistryDialog open={openRegistry} onClose={closeRegistryDialog} />
          </Suspense>
        ) : null}
      </Paper>
    </ThemeProvider>
  );
}

export default App;
