import { createMuiTheme } from "@material-ui/core/styles";

let h1, h2, h3, h4, h5, h6;
h1 = h2 = h3 = h4 = h5 = h6 = {
  fontFamily: 'Alex Brush',
};

export default createMuiTheme({
  typography: {
    fontFamily: 'Roboto',
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
  },
});
